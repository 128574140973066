<template>
  <div>
    <CwePageHeader :authenticationRequired="false" />

    <div id="EditProfile">

      <div class="centeredContentBox">

        <div class="back-section">
          <div class="back-section-inner">
            <a @click="cancel()">
              <font-awesome-icon icon="fa-solid fa-angle-left"></font-awesome-icon>
            </a>
          </div>
        </div>

        <form @submit="submitUser">

          <div class="heading">
              <button id="changePasswordButton" class="button-right button-secondary" type="button" @click="changePassword"
                      v-if="user.FederatedTenant === false">
                  {{ $t('azure_template_change_password_heading') }}
              </button>
            <h1 class="header-left" role="heading">{{ $t('my_profile') }}</h1>
          </div>

          <div v-if="messageDisplayKey == $t('edit_profile_changes_saved')" class="bottomSpace">
            <div v-if="messageDisplayKey" class="alertBox bottomSpace">{{ $t(messageDisplayKey) }}</div>
            <a href="#" @click="returnToDashboard()">{{ $t('return_dashboard') }}</a>
          </div>

          <div v-if="htmlErrorMessage" class="alertBox bottomSpace" v-html="htmlErrorMessage"></div>

          <div class="row">
            <div class="column">

              <div class="section">
                <div class="label">
                  <label :for="user.Prefix">{{ $t('prefix') }}</label>
                  <span class="optional"> - {{ $t('optional') }}</span>
                </div>

                <div>
                  <input class="prefixInput editProfileInput" v-model="user.Prefix" placeholder="prefix_placeholder" />
                </div>
              </div>

              <div class="nameRow">
                <div class="firstNameColumn">
                  <div class="section">
                    <div class="label">
                      <label :for="user.FirstName">{{ $t('first_name') }}</label>
                    </div>

                    <div>
                      <input class="firstNameInput editProfileInput" v-model="user.FirstName" />
                    </div>

                    <div class="error" v-if="firstNameError.length">{{ firstNameError }}</div>
                  </div>
                </div>

                <div class="lastNameColumn">
                  <div class="section">
                    <div class="label">
                      <label :for="user.LastName">{{ $t('last_name') }}</label>
                    </div>

                    <div>
                      <input class="lastNameInput editProfileInput" v-model="user.LastName" />
                    </div>

                    <div class="error" v-if="lastNameError.length">{{ lastNameError }}</div>
                  </div>
                </div>

              </div>

              <div class="section">
                <div class="label">
                  <label :for="user.Suffix">{{ $t('suffix') }}</label>
                  <span class="optional"> - {{ $t('optional') }}</span>
                </div>

                <div>
                  <input class="suffixInput editProfileInput" v-model="user.Suffix" placeholder="suffix_placeholder" />
                </div>
              </div>

              <div class="section">
                <div class="label">
                  <label :for="user.Npi">{{ $t('npi') }}</label>
                  <span class="optional"> - {{ $t('optional') }}</span>
                </div>

                <div>
                  <input class="npiInput editProfileInput" v-model="user.Npi" placeholder="npi_placeholder" maxlength="100" />
                </div>
              </div>
            </div>

            <div class="column">
              <div class="section">
                <PhoneNumberInput :phoneNumberLabel="$t('mobile_number')"
                                  :noMatchingCountriesLabel="$t('no_matching_countries')"
                                  :phoneCountryCodes="this.phoneCountryCodes"
                                  :phoneCountryCode="this.selectedCountryCode"
                                  :phoneNumber="this.user.Phone"
                                  @updatePhoneCountryCode="phoneCountryCodeChanged($event)"
                                  @updatePhoneNumber="phoneNumberChanged($event)"
                                  @updatePhoneNumberError="phoneNumberErrorChanged($event)" />

                <div class="error" v-if="phoneNumberError.length">{{ phoneNumberError }}</div>
              </div>

              <div class="section">
                <div class="label">
                  <label :for="user.Email">{{ $t('email_address') }}</label>
                </div>

                <div v-if="user.FederatedTenant === true">
                  {{user.Email}}
                </div>
                <div v-else>
                  <input class="emailInput editProfileInput" v-model="user.Email" />
                </div>

                <div class="error" v-if="user.FederatedTenant === false && emailError.length">{{ emailError }}</div>
              </div>
            </div>
          </div>

          <div id="contact-admin" v-if="user.FederatedTenant === true">
            <div class="contact">
              {{ $t('contact_admin_for_changes') }}
            </div>
          </div>

          <div class="button-section-right">
              <button class="button-right submit" type="submit">{{ $t('save_profile') }}</button>
              <button class="button-right go-back" type="button" @click="cancel()">{{ $t('cancel') }}</button>
          </div>

        </form>
      </div>

    </div>

  </div>
</template>

<script lang="ts">
import FixProxyUrl from '@/services/FixProxyUrl';
import { userProfileModule } from '@/store/userProfileModule';
import { CountryCode, PhoneCountryCodes } from '@/types/phoneCountryCodes';
import * as EmailValidator from 'email-validator';
import parsePhoneNumber, { CountryCode as CountryCodeEnum, isSupportedCountry, validatePhoneNumberLength, ValidatePhoneNumberLengthResult } from 'libphonenumber-js';
import * as Vue from 'vue';
import CwePageHeader from '../components/CwePageHeader.vue';
import PhoneNumberInput from '../components/PhoneNumberInput.vue';
import { User } from '../models/User';
import LoadAuthStatusAsync from '../services/AuthStatusLoader';
import { i18n } from '@/plugins/i18n'
const $t = i18n.global.t;

export default Vue.defineComponent({
    name: 'EditProfilePage',
    props: {},
    components: {
        CwePageHeader,
        PhoneNumberInput
    },
    data() {
        return {
            htmlErrorMessage: '',
            unchangedUserJSON: '',
            user: new User(),
            phoneCountryCodes: PhoneCountryCodes.sort((a, b) => a.Code > b.Code ? 1 : b.Code > a.Code ? -1 : 0),
            selectedCountryCode: undefined as CountryCode | undefined,
            emailError: '',
            firstNameError: '',
            lastNameError: '',
            phoneNumberError: '',
            messageDisplayKey: ''
        };
    },
    mounted: async function () {
        var tempCountryCodes = JSON.parse(JSON.stringify(this.phoneCountryCodes)) as Array<CountryCode>;

        for (var countryCodeIndex = 0; countryCodeIndex < tempCountryCodes.length; countryCodeIndex++) {
          var phoneCountryCode = this.phoneCountryCodes[countryCodeIndex] as CountryCode;

          if (phoneCountryCode && !isSupportedCountry(phoneCountryCode.Code))
            this.phoneCountryCodes.splice(countryCodeIndex, 1);
        }

        await this.LoadUser();
        await this.SetPlaceholders();
    },
    methods: {
        phoneCountryCodeChanged(countryCode: CountryCode) {
            this.user.PhoneCountryCode = countryCode.Ext;
            this.selectedCountryCode = countryCode;
        },
      phoneNumberChanged(phone: any) {
          this.user.Phone = phone;
        },
        phoneNumberErrorChanged(error: any) {
            this.phoneNumberError = error;
        },
        changesMade: function () {
            var changesMade = false;
            
            if (this.unchangedUserJSON != JSON.stringify(this.user))
                changesMade = true;
            
            return changesMade;
        },
        cancel: function () {
            var confirmed = false;
            
            if (this.changesMade()) {
                if (confirm($t('edit_profile_abandon_changes'))) {
                    confirmed = true;
                }
                
                if (confirmed) {
                    this.$router.push('Dashboard');
                }
            } else {
                this.$router.push('Dashboard');
            }
        },
      changePassword: async function (e: any) {
        e.preventDefault();

        if (this.changesMade()) {
          if (confirm($t('edit_profile_alert_save_changes'))) {
            var saved = await this.submitUser(null);
            if (!saved) return;
          }
        }
        window.location.href = FixProxyUrl('/resetpassword');        
      },
      submitUser: async function (e: any): Promise<boolean> {
          e?.preventDefault();
          
          this.messageDisplayKey = '';

          this.user.Email = this.user.Email.trim();
          this.user.Phone = this.user.Phone.trim();

          this.ClearErrors();
          this.SetErrors();
          
          if (this.ErrorsExist())
              return false;
          
          const apiResponse = await userProfileModule.saveUser(this.user);
          
          window.scrollTo(0, 0);
          
          if (apiResponse != null && apiResponse.errors != null && apiResponse.errors.length > 0) {
              
              let otherErrorsText = '';
              apiResponse.errors.forEach(err => {
                  if (err.key.toLowerCase().includes('phone')) {
                      this.phoneNumberError = err.localizedName;
                  } else {
                      if (otherErrorsText)
                          otherErrorsText += '<br/>';
                      otherErrorsText += err.localizedName;
                  }
              });
              
              this.htmlErrorMessage = otherErrorsText;
              
              return false;
          }
          
          this.messageDisplayKey = $t('edit_profile_changes_saved');
          await LoadAuthStatusAsync(true, true);
          
          return true;
      },
      returnToDashboard: async function () {
          this.$router.push('Dashboard');
      },
      SetPlaceholders: async function () {
          this.$el.querySelector('.prefixInput').placeholder = $t('prefix_placeholder');
          this.$el.querySelector('.suffixInput').placeholder = $t('suffix_placeholder');
          this.$el.querySelector('.npiInput').placeholder = $t('npi');
      },
      LoadUser: async function () {
          const result = await userProfileModule.loadUser();
          
          if (result == null) {
              this.$router.push('PreLogin');
              return;
          }
          
          this.selectedCountryCode = this.GetCountryCode(result.phoneCountryCode, result.phone, result.countryCode);
          this.user.Email = result.email;
          this.user.Prefix = result.prefix;
          this.user.FirstName = result.firstName;
          this.user.LastName = result.lastName;
          this.user.Suffix = result.suffix;
          this.user.Phone = this.FormatPhoneNumber(this.selectedCountryCode, result.phone);
          this.user.PhoneCountryCode = result.phoneCountryCode;
          this.user.CountryCode = result.countryCode;
          this.user.Birthday = result.birthday?.toString();
          this.user.Npi = result.npi;
          this.user.FederatedTenant = result.federatedTenant;
          this.user.PhoneRequired = result.phoneRequired;
          this.unchangedUserJSON = JSON.stringify(this.user);
      },
      GetCountryCode: function (phoneCountryCode: string, phoneNumber: string, countryCode: string) {
        let countryCodes = this.phoneCountryCodes.filter(cc => cc.Ext == phoneCountryCode);

        if (countryCodes.length > 0) {
          var parsedPhoneNumber = parsePhoneNumber('+' + phoneCountryCode + phoneNumber);
          let countryCodePhoneCountryCode: CountryCode|undefined = undefined;

          if (parsedPhoneNumber && parsedPhoneNumber.country && parsedPhoneNumber.country.length > 0) {
            countryCodePhoneCountryCode = countryCodes.find(cc => cc.Code == parsedPhoneNumber!.country);

            if (countryCodePhoneCountryCode != undefined)
              return countryCodePhoneCountryCode;
          }

          countryCodePhoneCountryCode = countryCodes.find(cc => cc.Code == countryCode);

          if (countryCodePhoneCountryCode != undefined)
            return countryCodePhoneCountryCode;

          if (countryCodes[0] != undefined) {
            return countryCodes[0];
          }
        }

        return new CountryCode();
      },
      FormatPhoneNumber(countryCode: CountryCode | undefined, phoneNumber: string) {
        if (countryCode && phoneNumber.length > 0) {
          var countryCodeEnum = countryCode.Code as CountryCodeEnum;
          var parsedPhoneNumber = parsePhoneNumber('+' + countryCode.Ext + phoneNumber, countryCodeEnum);

          return parsedPhoneNumber
            ? parsedPhoneNumber.formatNational()
            : phoneNumber;
        }
          
        return phoneNumber;
      },
      ClearErrors: function () {
          this.htmlErrorMessage = '';
          this.firstNameError = '';
          this.lastNameError = '';
          this.phoneNumberError = '';
          this.emailError = '';
      },
      SetErrors: function () {
          if (this.user.FederatedTenant == false) {
              if (!this.user.Email)
                  this.emailError = $t('required_email');
              else if (EmailValidator.validate(this.user.Email) == false) {
                  this.emailError = $t('required_email');
              }
          }
          
          if (!this.user.FirstName)
              this.firstNameError = $t('required_first_name');
          
          if (!this.user.LastName)
              this.lastNameError = $t('required_last_name');

          if (this.user.PhoneRequired) {
            if (!this.user.PhoneCountryCode && !this.selectedCountryCode)
              this.phoneNumberError = $t('required_country_code');
            else if (!this.user.Phone)
              this.phoneNumberError = $t('required_phone');
          }
          else {
            // Ensure if the user enters a phone number, they have to select a country
            if (this.user.Phone.length > 0 && (this.user.PhoneCountryCode.length == 0 || !this.selectedCountryCode))
              this.phoneNumberError = $t('invalid_phone_number');
            // If the user selects a country but no phone number, then clear it out.
            else if (this.user.PhoneCountryCode.length > 0 && this.user.Phone.length == 0 && this.selectedCountryCode)
              this.user.PhoneCountryCode = '';
              this.selectedCountryCode = undefined;
          }
          if (this.selectedCountryCode && this.user.Phone) {
            var countryCodeEnum = this.selectedCountryCode.Code as CountryCodeEnum;
            var parsedPhoneNumber = parsePhoneNumber("+" + this.selectedCountryCode.Ext + this.user.Phone, countryCodeEnum);

            if (parsedPhoneNumber) {
              var validPhoheNumber = parsedPhoneNumber.isValid();
              var validPhoneNumberLength = validatePhoneNumberLength("+" + this.selectedCountryCode.Ext + this.user.Phone, countryCodeEnum) as ValidatePhoneNumberLengthResult;

              if (validPhoheNumber == false || validPhoneNumberLength == 'TOO_LONG')
                this.phoneNumberError = $t('invalid_phone_number');
            }
          }
      },
      ErrorsExist: function () {
          if (this.user.FederatedTenant == false)
              return this.emailError || this.firstNameError || this.lastNameError || this.phoneNumberError;
          
          return this.firstNameError || this.lastNameError || this.phoneNumberError;
      }
    }
});
</script>

<style lang="scss">
  #EditProfile {
    padding-top: 10px;

    .back-section {
      position: relative;

      .back-section-inner {
        position: absolute;
        top: 15px;
        left: -80px;
        cursor: pointer;
      }

      @media screen and ( max-width: 900px ) {
        .back-section-inner {
          display: none;
        }
      }

      .fa-angle-left {
        font-size: 18pt;
        color: $dark_accent_color;
      }
    }

    .centeredContentBox {
      width: 80%;
      max-width: 800px;

      @media screen and ( max-width: 900px ) {
        width: auto;
      }

      .go-back {
        background-color: $very_light_gray;
        &:hover {
          background-color: $secondary_button_color_hover;
        }
      }

      #changePasswordButton {
        padding: 9px 40px 10px;
        background-color: $very_light_gray;
        color: $cancel_button_text_color;
      }

      .heading {
        margin-bottom: $standard_space * 2;
      }

      .header-left {
        color: $primary_button_color;
      }

      label {
        font-weight: $font_weight_bold;
      }

      a {
        text-decoration: none;
        font-weight: $font_weight_bold;
        color: $cancel_button_text_color;
      }

      a:hover {
        font-weight: $font_weight_bold;
        color: $cancel_button_text_color_hover;
      }

      p {
        margin: 0;
        padding: 0;
        color: $dark_accent_color;
        font-weight: $font_weight_bold;
      }

      .editProfileInput {
        background-color: $white;
        height: 2.5rem;
        font-size: 0.9rem;
        width: 100%;
        max-width: 550px;
        border: 1px solid #d8d8d8;
        border-radius: 6px;
        @media screen and ( max-width: 900px ) {
          max-width: 200px;
        }
        @media screen and ( max-width: 600px ) {
          max-width: unset;
        }
      }

      .row {
        display: flex;
      }

      .row .column {
        flex: 1;
        max-width: 50%;
      }

      @media screen and ( max-width: 800px ) {
        .row {
          display: block;

          .column {
            flex: 1;
            max-width: 100%;
          }
        }
      }

      .nameRow {
        display: flex;

        @media screen and ( max-width: 600px ) {
          flex-direction: column;

          .lastNameColumn {  
            margin-left:0 !important; 
            margin-right:0 !important; 
          }
        }
      }

      .nameRow > .firstNameColumn {
        flex: 1 1 auto;
        min-width: 150px;
        max-width: 100%;
      }

      .nameRow > .lastNameColumn {
        flex: 1 1 auto;
        min-width: 150px;
        max-width: 100%;
        margin-left: $standard_space;
      }

      .phoneNumberExampleColumn {
        padding-left: 5px;
      }
      
      @media screen and ( max-width: 600px ) {
        .phoneNumberRow, #phoneNumber {
          max-width: unset;
        }
      }

      .multiselect__tags, #phoneNumber {
        background: $white;
        border: 1px solid #d8d8d8;
        border-radius: 6px;
      }

      #contact-admin {
        margin-top: $standard_space;

        .contact {
          margin-top: $standard_space*2;
        }
      }

      .change-password {
        max-width: 200px;
        @include secondary-button-style();
        font-size: .8rem;
        text-transform: none;
      }

      .button-section {
        margin-top: $standard_space*2;
        margin-bottom: 0px;
        display: flex;
        justify-content: flex-end;
      }

      .submit,
      .cancel {
        max-width: 200px;
        font-size: .9rem;
        text-transform: none;
      }

      .cancel {
        background: none !important;
        border: none;
        padding: 0 !important;
        font-weight: normal;
        font-size: .9rem;
        text-decoration: underline;
        cursor: pointer;
        margin-right: -40px;
        color: $dark_accent_color;
      }
    }
  }
</style>