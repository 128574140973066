<template>
    <div>
        <CwePageHeader :authenticationRequired="true" />

        <div id="PendingInvitesPage" class="shared-page">
            <div class="centeredContentBox">

                <h3 class="bottomSpaceDouble header-left">{{ $t('pending_invites_title') }}</h3>

                <div v-if="pageErrorMessage">
                    <p class="alertBox textLarge bottomSpaceDouble">{{ pageErrorMessage }}</p>
                </div>

                <div v-if="pendingInvites && pendingInvites.length>0">
                    <p class="textLarge bottomSpace">{{ $t('pending_invites_instructions') }}</p>

                    <ul id="matchingInvites">
                        <li v-for="invite in pendingInvites" :key="invite.inviteID" @click="LoadInvite(invite)">
                            <b>{{FormatClientName(invite.clientName)}}</b>{{FormatApplicationNames(invite.applicationNames)}}
                        </li>
                    </ul>
                </div>

                <div class="button-section-center">
                    <button class="button button-secondary"
                            type="button"
                            @click="skipButtonClick"
                            data-testid="skipButton">
                        {{ $t('pending_invites_skip_button') }}
                    </button>
                </div>

            </div>
        </div>
    </div>

</template>

<script lang="ts">
    import axios from 'axios';
    import * as Vue from 'vue';
    import { ApiRoutes } from '../api/ApiRoutes';
    import { IStandardResponse } from '../models/StandardResponse';
	import CwePageHeader from '../components/CwePageHeader.vue';
	import ErrorUrlFromStatusCode from '../services/ErrorUrlFromStatusCode';
	import { i18n } from '@/plugins/i18n'
	const $t = i18n.global.t;

    interface IPendingInvite {
        inviteID: number;
        inviteCode: string;
        clientName: string;
        applicationNames: Array<string>;
    }

    interface IPendingInvitesResponse extends IStandardResponse {
        pendingInvites: Array<IPendingInvite>;
    }

    interface PendingInvitesData {
        initiated: boolean,
        pageErrorMessage: string | undefined,
        pendingInvites: Array<IPendingInvite>
    }

    export default Vue.defineComponent({
        name: 'PendingInvitesPage',
        components: {
            CwePageHeader,
        },
        data(): PendingInvitesData {
            return {
                initiated: false,
                pageErrorMessage: undefined,
                pendingInvites: []
            }
		},
		mounted: async function () {
			await this.LoadPendingInvites();
		},
        methods: {
            goBack: function () {
                this.$router.back();
            },
			skipButtonClick: function () {
				this.$router.push('Dashboard');
            },

			LoadPendingInvites: async function () {

                this.$store.state.loadingCount++;

				axios.get(ApiRoutes.User.PendingInvites).then((response) => {

					const pendingInvitesResponse = response.data as IPendingInvitesResponse;

					if (pendingInvitesResponse.pendingInvites == null || pendingInvitesResponse.pendingInvites.length == 0) {
						this.pageErrorMessage = $t('find_invites_none_found');
					}
					else {
						this.pendingInvites = pendingInvitesResponse.pendingInvites;
					}

				})
				.catch((error) => {
					const findInvitesResponse = error.response?.data as IPendingInvitesResponse;

					if ((error?.response?.status == 422 || error?.response?.status == 400) && findInvitesResponse?.errors?.length > 0) {
						this.pageErrorMessage = findInvitesResponse.errors[0].localizedName;
					} else {
						window.location.href = ErrorUrlFromStatusCode(error?.response?.status);
					}
				})
				.finally(() => {
					this.$store.state.loadingCount--;
				});
			},

            FormatApplicationNames: function (applicationNames: Array<string>) {
                if (!applicationNames) return '';
                return applicationNames.join(', ');
            },
            FormatClientName: function (clientName: string | undefined) {
                if (!clientName) return '';
                return clientName + ' ';
            },
            LoadInvite: function (invite: IPendingInvite) {
                this.$router.push({ name: 'RedeemInvitePage', query: { id: invite.inviteID, code: invite.inviteCode } });
            }
        }
    })
</script>

<style lang="scss">
    .buttonWrap {
        margin-bottom: $standard_space;
    }

    .back-section {
        position: relative;

        .back-section-inner {
            position: absolute;
            top: 15px;
            left: -80px;
            cursor: pointer;
        }

        @media screen and ( max-width: 900px ) {
            .back-section-inner {
                display: none;
            }
        }

        .fa-angle-left {
            font-size: 18pt;
            color: $dark_accent_color;
        }
    }

    button {
        min-width: 350px;
    }

    #PendingInvitesPage {
        display: flex;

        p {
            text-align: left;
        }

        .alertBox {
            text-align: center;
        }

        input {
            height: 2.5rem;
        }
    }

    ul#matchingInvites {
        list-style: none;
        margin: $standard_space 0;
        padding: 0;
        text-align: left;
        border: 1px solid $gray;
        background: $very_light_gray;

        li {
            padding: $standard_space/2;
            margin: 0;
            border-bottom: 1px solid $gray;
            cursor: pointer;

            &:hover {
                background: #ededed;
            }

            &:last-child {
                border-bottom: 0 none;
            }
        }
    }
</style>
