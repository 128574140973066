<template>
  <CwePageHeader :authenticationRequired="true" />

  <div id="RecoverPinPage" class="shared-page">
    <div class="centeredContentBox">
      <div class="back-section">
        <div class="back-section-inner">
          <a v-if="currentStep == 'showPins'" @click="back()">
            <font-awesome-icon icon="fa-solid fa-angle-left"></font-awesome-icon>
          </a>
        </div>
      </div>

      <h3 class="bottomSpaceDouble">{{ $t('recover_pin_title') }}</h3>

      <div v-if="pageErrorMessage">
        <p class="alertBox textLarge bottomSpaceDouble">{{ pageErrorMessage }}</p>
      </div>

      <div v-if="currentStep=='collectEmail'">
        <p class="textLarge bottomSpace">{{ $t('recover_pin_email_instructions') }}</p>

        <form id="ValidateEmailForm" @submit="SubmitEmail">
          <div class="section">
            <div class="label">
              <label :for="email">{{ $t('email_address') }}</label>
            </div>
            <div class="bottomSpaceDouble">
              <input v-model="email" maxlength="100" type="email" />
            </div>
            <div class="error" v-if="emailError.length">{{ emailError }}</div>
          </div>

          <div class="buttonWrap">
            <button class="button" type="submit" value="EmailFormSubmit">{{ $t('submit') }}</button>
          </div>
        </form>
      </div>

      <div v-if="currentStep=='showPins'">
        <div class="section">
          <div class="buttonWrap">
            <button class="button" @click="SubmitEmail(null)">Refresh</button>
          </div>

          <p class="textLarge bottomSpace">{{ $t('recover_pin_list_header') }}</p>
          <p class="textMed bottomSpace">{{ $t('email_address') }}: {{ email }}</p>

          <div v-if="userVerificationRecoveryPin != null">
            <p class="textS bottomSpace">{{ $t('recover_pin_user_verification') }}</p>

            <table class="bottomSpace">
              <tr>
                <th>{{ $t('pin') }}</th>
                <th>{{ $t('expiration_time') }}</th>
              </tr>

              <tr>
                <td>{{ GetPin(userVerificationRecoveryPin) }}</td>
                <td>{{ GetExpirationTime(userVerificationRecoveryPin) }}</td>
              </tr>
            </table>
          </div>

          <div v-if="inviteRecoveryPins != null && inviteRecoveryPins.length > 0">
            <p class="textS bottomSpace">{{ $t('recover_pin_invites') }}</p>

            <table>
              <tr>
                <th>{{ $t('pin') }}</th>
                <th>{{ $t('recover_pin_client') }}</th>
                <th>{{ $t('expiration_time') }}</th>
                <th>{{ $t('redeem_link') }}</th>
              </tr>

              <tr v-for="recoveryPin in inviteRecoveryPins" :key="recoveryPin.pin">
                <td>{{ GetPin(recoveryPin) }}</td>
                <td>{{ recoveryPin.clientName }}</td>
                <td>{{ GetExpirationTime(recoveryPin) }}</td>
                <td class="recoverPinButton"><button v-if="recoveryPin.inviteId != undefined && recoveryPin.inviteId > 0" @click="RedeemInvite(recoveryPin)">{{ GetRecoverPinButtonText(recoveryPin) }}</button></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import axios from 'axios';
  import * as EmailValidator from 'email-validator';
  import * as Vue from 'vue';
  import { ApiRoutes } from '../api/ApiRoutes';
  import CwePageHeader from '../components/CwePageHeader.vue';
  import { IStandardResponse } from '../models/StandardResponse';
  import ErrorUrlFromStatusCode from '../services/ErrorUrlFromStatusCode';
  import { store } from '@/store/store';
  import { i18n } from '@/plugins/i18n'
  const $t = i18n.global.t;

  interface IRecoveryPin {
    pin: string;
    clientName: string;
    expirationTime: Date | undefined;
    inviteId: string;
    inviteCode: string;
  }

  interface IRecoverActivationCodeResponse extends IStandardResponse {
    userVerificationRecoveryPin: IRecoveryPin,
    inviteRecoveryPins: Array<IRecoveryPin>,
    noPinsForUser: boolean;
  }

  interface RecoverPinData {
    currentStep: string,
    email: string;
    emailError: string,
    pageErrorMessage: string | undefined,
    userVerificationRecoveryPin: IRecoveryPin | undefined,
    inviteRecoveryPins: Array<IRecoveryPin>;
  }

  export default Vue.defineComponent({
    name: 'RecoverPinPage',
    components: {
      CwePageHeader,
    },
    props: [],
    data(): RecoverPinData {
      return {
        currentStep: "collectEmail",
        email: '',
        emailError: '',
        pageErrorMessage: undefined,
        userVerificationRecoveryPin: undefined,
        inviteRecoveryPins: []
      }
    },
    computed: {
    },
    methods: {
      back: function () {
        if (this.currentStep == 'showPins')
          this.currentStep = 'collectEmail';
      },
        SubmitEmail: async function (e: any) {
        if (e)
          e.preventDefault();

        this.email = this.email?.trim();

        this.ClearErrors();
        this.SetErrors();

        if (this.ErrorsExist())
          return false;

        this.$store.state.loadingCount++;

        var query = `?email=${encodeURIComponent(this.email)}`;

        axios.get(ApiRoutes.Auth.RecoverPin + query).then((response) => {

          const recoverActivationCodeResponse = response.data as IRecoverActivationCodeResponse;
          var allPinsVerified = recoverActivationCodeResponse.userVerificationRecoveryPin == null
              && (recoverActivationCodeResponse.inviteRecoveryPins == null || recoverActivationCodeResponse.inviteRecoveryPins.length == 0);

          if (recoverActivationCodeResponse.noPinsForUser)
            this.pageErrorMessage = $t('no_recovery_pins_for_user');
          else if (allPinsVerified)
            this.pageErrorMessage = $t('recover_pin_all_verified');
          else
            this.currentStep = "showPins";

          this.userVerificationRecoveryPin = recoverActivationCodeResponse.userVerificationRecoveryPin;
          this.inviteRecoveryPins = recoverActivationCodeResponse.inviteRecoveryPins;
        })
          .catch((error) => {
            const recoverActivationCodeResponse = error.response?.data as IRecoverActivationCodeResponse;

            if ((error?.response?.status == 422 || error?.response?.status == 400) && recoverActivationCodeResponse?.errors?.length > 0) {
              this.pageErrorMessage = recoverActivationCodeResponse.errors[0].localizedName;
            } else {
              window.location.href = ErrorUrlFromStatusCode(error?.response?.status);
            }
          })
          .finally(() => {
            this.$store.state.loadingCount--;
          });
      },
      GetPin: function (recoveryPin: IRecoveryPin) {
        if (recoveryPin.pin == undefined || recoveryPin.pin.length == 0)
          return $t('recover_pin_verification_not_started');

        if (recoveryPin.pin === 'Expired')
          return $t('recover_pin_has_expired').replace(".", "");

        return recoveryPin.pin;
      },
      GetExpirationTime: function (recoveryPin: IRecoveryPin) {
        if (recoveryPin.expirationTime == undefined)
          return recoveryPin.expirationTime;

        var date = new Date(recoveryPin.expirationTime.toString());

        return date.toLocaleDateString(store.state.browserLocale) + ' ' + date.toLocaleTimeString(store.state.browserLocale);
      },
      RedeemInvite: async function (recoveryPin: IRecoveryPin) {
        if (recoveryPin.pin == undefined || recoveryPin.pin.length == 0 || recoveryPin.pin === 'Expired') {
          const routeData = this.$router.resolve({ name: 'RedeemInvitePage', query: { id: recoveryPin.inviteId, code: recoveryPin.inviteCode } });
          window.open(routeData.href);
        }
        else {
          const routeData = this.$router.resolve({ name: 'RedeemInvitePage', query: { id: recoveryPin.inviteId, code: recoveryPin.inviteCode, phonePin: recoveryPin.pin } });
          window.open(routeData.href);
        }
      },
      ClearErrors: function () {
        this.emailError = '';
        this.pageErrorMessage = '';
      },
      SetErrors: function () {
        if (!this.email || EmailValidator.validate(this.email) == false)
          this.emailError = $t('required_email');
      },
      ErrorsExist: function () {
        return this.emailError;
      },
      GetRecoverPinButtonText: function (recoveryPin: IRecoveryPin) {
        if (recoveryPin.pin == undefined || recoveryPin.pin.length == 0 || recoveryPin.pin === 'Expired')
          return 'Resend Pin';
        else
          return 'Redeem Invite';
      }
    },
    mounted() {

    }
  });
</script>

<style lang="scss">
  #RecoverPinPage {
    .back-section {
      position: relative;

      .back-section-inner {
        position: absolute;
        top: 15px;
        left: -80px;
        cursor: pointer;
      }

      @media screen and ( max-width: 900px ) {
        .back-section-inner {
          display: none;
        }
      }

      .fa-angle-left {
        font-size: 18pt;
        color: $dark_accent_color;
      }
    }

    div {
      text-align: center;
    }

    p {
      text-align: center;
    }

    table, th, tr, td {
      table-layout: fixed;
      width: 100%;
      height: 100%;
      border: 1px solid black;
      text-align: center;
      font-size: 0.8rem;
      word-break: break-word;
    }

    .recoverPinButton > button {
      all: unset;
      background-color: #b41e8e;
      color: #fff;
      font-weight: 600;
      height: 100%;
      width: 100%;
      cursor: pointer;

      &:hover {
        background-color: #901872;
        color: #fff;
        border-color: #901872;
      }

      &:disabled {
        background-color: #e8bbdd;
        color: #fff;
        border-color: #e8bbdd;
      }
    }
  }
</style>
