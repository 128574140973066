import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  id: "PendingInvitesPage",
  class: "shared-page"
}
const _hoisted_2 = { class: "centeredContentBox" }
const _hoisted_3 = { class: "bottomSpaceDouble header-left" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "alertBox textLarge bottomSpaceDouble" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "textLarge bottomSpace" }
const _hoisted_8 = { id: "matchingInvites" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "button-section-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CwePageHeader = _resolveComponent("CwePageHeader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CwePageHeader, { authenticationRequired: true }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('pending_invites_title')), 1),
        (_ctx.pageErrorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.pageErrorMessage), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.pendingInvites && _ctx.pendingInvites.length>0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('pending_invites_instructions')), 1),
              _createElementVNode("ul", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingInvites, (invite) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: invite.inviteID,
                    onClick: ($event: any) => (_ctx.LoadInvite(invite))
                  }, [
                    _createElementVNode("b", null, _toDisplayString(_ctx.FormatClientName(invite.clientName)), 1),
                    _createTextVNode(_toDisplayString(_ctx.FormatApplicationNames(invite.applicationNames)), 1)
                  ], 8, _hoisted_9))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("button", {
            class: "button button-secondary",
            type: "button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.skipButtonClick && _ctx.skipButtonClick(...args))),
            "data-testid": "skipButton"
          }, _toDisplayString(_ctx.$t('pending_invites_skip_button')), 1)
        ])
      ])
    ])
  ]))
}