import { store, sessionModule } from '@/store';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import LoadAuthStatusAsync from '../services/AuthStatusLoader';
import FixProxyUrl from '../services/FixProxyUrl';
import { AuthPolicyEnum } from '@/constants/authPolicies';
import { SendActivationCodeMode } from '@/constants/enums';


export default async function RouteGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const allowAnonymousMatch = to.matched.find(record => record.meta.allowAnonymous);
  const startupMatch = to.matched.find(record => record.meta.isStartupPage);

  if(to.query.app && to.query.app.length > 0)
    store.state.appRedirect = to.query.app.toString().replace(/[^a-zA-Z ]+/g, '');

  if (allowAnonymousMatch && !startupMatch)
    return null;

  await LoadAuthStatusAsync(true, false);

  const policiesMeta = to.matched[0].meta.policies;

  if (policiesMeta) {
    let authorized = false;

    // If using an array of policies for a page, it will be authorized if ANY of them match, not requiring ALL to match.
    if (Array.isArray(policiesMeta)){
      policiesMeta.forEach(policyName=>{
        if(sessionModule.policies.some(p => p === policyName))
          authorized=true;
      });
    } else if (typeof policiesMeta === 'function') {
      authorized = policiesMeta(sessionModule.policies, store);
    } else if (typeof policiesMeta === 'string') {
      authorized = sessionModule.policies.some(p => p === policiesMeta);
    }

    if (authorized)
      return null;
  }

  if (!store.state.apiServerAvailable) {
    const errorUrl = '/error?errorKey=';
    if (!window.location.href.toLowerCase().includes(errorUrl.toLowerCase()))
      window.location.href = errorUrl + 'user_status_load_failed';

    return null;
  }

  if( sessionModule.policies.some(p => p === AuthPolicyEnum.Registration ) )
    return '/policies';

  if (sessionModule.policies.some(p => p === AuthPolicyEnum.IPValidation))
    return '/confirm-identity?mode=' + SendActivationCodeMode.identity_validation;

  if (store.state.loggedIn && store.state.matchingUserFound) {

    if (startupMatch) {
        if (store.state.hasUnredeemedInvites)
            return '/pending-invites';
        else 
            return '/dashboard';
    }

    return null;
  } else {

    if (store.state.loggedIn && !store.state.matchingUserFound)
      return FixProxyUrl('/logout?errorKey=no_matching_user_found');

    if(store.state.appRedirect)
      return '/prelogin?app='+store.state.appRedirect;

    return '/prelogin';
  }
}