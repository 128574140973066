export const ApiRoutes = {
  AppConfig: {
    GetAppConfig: '/api/config',
    GetCountries: '/api/config/countries',
    GetLocalizedStrings: '/api/config/localized-strings',
  },
  Auth: {
    GetAntiForgeryToken: 'api/auth/antiforgery',
    GetAuthAppRedirectToken: '/api/auth/app-redirect-token',
    GetAuthStatus: '/api/auth/status',
    SendVerificationCode: '/api/auth/activation-code:send',
    ValidateVerificationCode: '/api/auth/activation-code:validate',
    GetPolicy: '/api/auth/policy:info',
    GetUserPolicies: '/api/auth/user-policy:infos',
    SignUserPolicies: '/api/auth/user-policy:signoffs',
    RefreshUserToken: '/api/auth/refresh',
    RecoverPin: '/api/auth/activation-code:recover'
  },
  User: {
    GetInvite: '/api/user/invite',
    FindInvites: '/api/user/find-invites',
    PendingInvites: '/api/user/pending-invites',
    RegisterInvite: '/api/user/register',
    Profile: '/api/user/profile',
    Password: '/api/user/password'
  }
}